import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getContentItemQueryKey } from '#/packages/content-item/queries/useContentItemQuery';
import { getContentItemsQueryKey } from '#/packages/content-item/queries/useContentItemsQuery';
import { ContentItem } from '#/packages/content-item/types/ContentItem';
import httpClient from '#/src/api/backendHttpClient';
import { useSessionWorkspaceId } from '#/src/context/session/hooks/useWorkspace';

export function useDeleteContentItemMutation() {
  const queryClient = useQueryClient();
  const workspaceId = useSessionWorkspaceId();
  return useMutation({
    mutationFn: (id: string) => {
      return httpClient.del<void>(`/content-item/${id}`).then(() => id);
    },
    onSuccess: (id) => {
      queryClient.setQueryData<ContentItem[]>(getContentItemsQueryKey(workspaceId!), (input) => {
        if (input) {
          return input
            .map((it) => {
              if (it.id == id) {
                return null;
              }
              if (it.children.includes(id)) {
                return { ...it, children: it.children.filter((c) => c != id) };
              } else {
                return it;
              }
            })
            .filter(Boolean) as ContentItem[];
        }
      });
      queryClient.setQueryData<ContentItem>(getContentItemQueryKey(id!), undefined);
    }
  });
}
