'use client';

import { useParams, useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDeleteContentItemMutation } from '#/packages/content-item/queries/useDeleteContentItemMutation';
import { Button, Modal } from '#/packages/ui';
import { useContentItemsActionsContext } from './ContentItemActionsProvider';

export const ContentItemDeleteModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { actionAndContentItem } = useContentItemsActionsContext();
  const { id, name, isFolder } = actionAndContentItem ?? {};

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: { id }
  });

  const navigate = useRouter();
  const { uuid } = useParams();
  const deleteItem = useDeleteContentItemMutation();

  const contentType = isFolder ? 'Board' : 'Report';
  const title = `Delete ${name}`;
  const message = `Are you sure you want to delete this ${contentType}?`;

  const formHandler = useCallback(() => {
    if (!id) {
      onClose();
      return;
    }

    deleteItem.mutateAsync(id).then(() => {
      onClose();
      toast.success(`${contentType} ${name} deleted`);

      if (id === uuid) {
        navigate.replace('/');
      }
    });
  }, [contentType, deleteItem, id, name, navigate, onClose, uuid]);

  return (
    <Modal title={title} onClose={onClose} show>
      <form onSubmit={handleSubmit(formHandler)}>
        <div className='sm:flex sm:items-start w-full'>
          <p className='w-full text-sm'>{message}</p>
        </div>
        <div className='mt-5 sm:mt-6 sm:flex sm:flex-row-reverse gap-2'>
          <Button
            color='destructive'
            size='md'
            type='submit'
            isLoading={isSubmitting || deleteItem.isPending}
          >
            Delete
          </Button>
        </div>
      </form>
    </Modal>
  );
};
