import { QueryClient } from '@tanstack/react-query';
import { getContentItemQueryKey } from '#/packages/content-item/queries/useContentItemQuery';
import { getContentItemsQueryKey } from '#/packages/content-item/queries/useContentItemsQuery';
import { ContentItem } from '#/packages/content-item/types/ContentItem';

export type ItemMutateContext = {
  contentItemId: string;
  prev?: ContentItem | Partial<ContentItem>;
};
export const onItemOptimisticMutate = async (
  contentItemId: string | null | undefined,
  data: Partial<ContentItem>,
  { queryClient, workspaceId }: { queryClient: QueryClient; workspaceId?: string | null }
): Promise<ItemMutateContext | null> => {
  if (!contentItemId) {
    return null;
  }

  await queryClient.cancelQueries({ queryKey: getContentItemsQueryKey(workspaceId!) });
  await queryClient.cancelQueries({ queryKey: getContentItemQueryKey(contentItemId) });

  const prev = await queryClient.getQueryData<ContentItem>(getContentItemQueryKey(contentItemId));

  await setItem(contentItemId, data, { queryClient, workspaceId });

  return {
    contentItemId,
    prev
  };
};
export const onItemOptimisticMutateSuccess = (
  contentItemId: string | null | undefined,
  data: Partial<ContentItem>,
  { queryClient, workspaceId }: { queryClient: QueryClient; workspaceId?: string | null }
) => setItem(contentItemId || null, data, { workspaceId, queryClient });

export const onItemOptimisticMutateError = (
  context: ItemMutateContext | null | undefined,
  { queryClient, workspaceId }: { queryClient: QueryClient; workspaceId?: string | null }
) =>
  context?.prev &&
  setItem(context.contentItemId || null, context.prev, { workspaceId, queryClient });

export const setItem = async (
  id: string | null,
  data: Partial<ContentItem>,
  { queryClient, workspaceId }: { queryClient: QueryClient; workspaceId?: string | null }
) =>
  id &&
  Promise.all([
    queryClient.setQueryData<ContentItem>(getContentItemQueryKey(id!), (old) => {
      if (old) {
        return { ...old, ...data };
      }
    }),
    queryClient.setQueryData<ContentItem[]>(
      getContentItemsQueryKey(workspaceId!),
      (input) => input && [...input.map((it) => (id === it.id ? { ...it, ...data } : it))]
    )
  ]);
