'use client';

import React, { ReactNode, createContext, useContext, useState } from 'react';
import { ContentItemDeleteModal } from '#/packages/content-item/components/ContentItemDeleteModal';
import { ContentItemUpdateModal } from '#/packages/content-item/components/ContentItemUpdateModal';
import { PAYWALL_TYPE } from '#/packages/subscription/types/Checkout';
import { ContentItemLimitModal } from './ContentItemLimitModal';

type ActionAndContentItemType = {
  name: string;
  id: string;
  isFolder: boolean;
  action: 'rename' | 'delete';
  description?: string;
};

type ContentItemActionsContextType = {
  actionAndContentItem: ActionAndContentItemType | undefined;
  setActionAndContentItem: React.Dispatch<
    React.SetStateAction<ActionAndContentItemType | undefined>
  >;
  paywallTrigger: (key: PAYWALL_TYPE) => void;
};

const ContentItemActionsContext = createContext<ContentItemActionsContextType | undefined>(
  undefined
);

export const ContentItemActionsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [actionAndContentItem, setActionAndContentItem] = useState<ActionAndContentItemType>();
  const [paywallType, setPaywallType] = useState<PAYWALL_TYPE | undefined>(undefined);

  const paywallTrigger = (type?: PAYWALL_TYPE) => {
    setPaywallType(type);
  };

  const onClose = () => {
    setActionAndContentItem(undefined);
  };

  return (
    <ContentItemActionsContext.Provider
      value={{
        actionAndContentItem,
        setActionAndContentItem,
        paywallTrigger
      }}
    >
      {actionAndContentItem?.action === 'rename' && <ContentItemUpdateModal onClose={onClose} />}
      {actionAndContentItem?.action === 'delete' && <ContentItemDeleteModal onClose={onClose} />}

      {Boolean(paywallType) && (
        <ContentItemLimitModal onClose={() => setPaywallType(undefined)} type={paywallType} />
      )}

      {children}
    </ContentItemActionsContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useContentItemsActionsContext = (): ContentItemActionsContextType => {
  const context = useContext(ContentItemActionsContext);

  if (!context) {
    throw new Error(
      'useContentItemsActionsContext must be used within a ContentItemActionsProvider'
    );
  }

  return context;
};
