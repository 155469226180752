export type CheckoutRequest = {
  successUrl: string;
  cancelUrl: string;
  workspaceUuid: string;
};

export type CheckoutResponse = {
  url: string;
  success: boolean;
};

export enum SubscriberStatus {
  FREE = 'FREE',
  TRIALING = 'TRIALING',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  UNKNOWN = 'UNKNOWN'
}

export enum SubscriptionType {
  COMMUNITY = 'COMMUNITY',
  PROFESSIONAL = 'PROFESSIONAL',
  AGENCY = 'AGENCY'
}

export interface Flags {
  contentItemReportsLeft: number;
  aiDimension: boolean;
  multiAccount: boolean;
}

export type Subscriber = {
  id: string;
  workspaceUuid: string;
  status: SubscriberStatus;
  type: SubscriptionType;
  flags: Flags;
  invoiceDateStart: string;
  invoiceDateEnd: string;
  currentAdsSpent: number;
  cancelAtPeriodEnd?: boolean;
  subscriptionOwnerId: string;
};

export enum PAYWALL_TYPE {
  CONTENT_ITEMS_LIMIT = 'content-items-limit',
  MULTI_ACCOUNT = 'multi-account'
}
