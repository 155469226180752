import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useCallback, useState } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  useUpdateContentItemDescriptionMutation,
  useUpdateContentItemNameMutation
} from '#/packages/content-item/queries/useContentItemMutation';
import { isFolderContentItem } from '#/packages/content-item/utils/type';
import { Button, Modal, TextInput, firstLetterUp } from '#/packages/ui';
import { useContentItemsActionsContext } from './ContentItemActionsProvider';
import { useContentItems } from '../hooks/useContentItems';

export const ContentItemUpdateModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { actionAndContentItem } = useContentItemsActionsContext();
  const { id } = actionAndContentItem ?? {};
  const { contentItemsMap } = useContentItems({});
  const contentItem = id ? contentItemsMap.get(id) : undefined;
  const { name, description } = contentItem ?? {};

  const [descriptionValue, setDescriptionValue] = useState(description || '');

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: { id, name, description }
  });

  const updateName = useUpdateContentItemNameMutation(id);
  const updateDescription = useUpdateContentItemDescriptionMutation(id);
  const contentType = isFolderContentItem(contentItem) ? 'board' : 'report';

  const formHandler = useCallback(
    async (form: FieldValues) => {
      return Promise.all([
        updateName.mutateAsync({ name: form.name }),
        // TODO: use single BE endpoint. Rn avoiding ratelimit from BE with sleep function
        await new Promise((resolve) => setTimeout(resolve, 500)),
        updateDescription.mutateAsync(descriptionValue)
      ])
        .then(() => {
          onClose();
          toast.success(`${firstLetterUp(contentType)} updated`, { icon: undefined });
        })
        .catch(() => {
          onClose();
          toast.error(`Something went wrong. Please try again later`);
        });
    },
    [contentType, onClose, updateDescription, updateName, descriptionValue]
  );

  const handleDescriptionChange = useCallback((e: ContentEditableEvent) => {
    setDescriptionValue(e.target.value);
  }, []);

  const title = `Rename ${contentType}`;

  if (!id) {
    return null;
  }

  return (
    <Modal title={title} show={true} onClose={onClose}>
      <form onSubmit={handleSubmit(formHandler)}>
        <div className='sm:flex sm:items-start w-full'>
          <div className='w-full'>
            <label htmlFor='name' className='block text-sm font-medium leading-6 text-gray-900'>
              Name
            </label>
            <div className='relative mt-1 rounded-md'>
              <TextInput
                type='text'
                id='name'
                invalid={Boolean(errors.name)}
                placeholder='E.g. Coca Cola'
                aria-invalid='true'
                aria-describedby='email-error'
                {...register('name', { required: true })}
              />
              {errors.name && (
                <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                  <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                </div>
              )}
            </div>

            <label
              htmlFor='description'
              className='block text-sm font-medium leading-6 text-gray-900 mt-4'
            >
              Description <span className='text-gray-400 text-xs ml-1'>Optional</span>
            </label>
            <div className='relative mt-1 rounded-md'>
              <ContentEditable
                html={descriptionValue}
                onChange={handleDescriptionChange}
                placeholder={'Enter short description'}
                className='w-full appearance-none min-h-16 max-h-32 overflow-y-scroll focus:outline-[0] focus:ring-1 focus:ring-inset focus:ring-primary-5 transition-all ease-in-out duration-150 rounded py-[7px] px-3 text-md sm:text-sm sm:leading-6 border border-neutral-3 hover:border-neutral-4 text-gray-750 placeholder:text-gray-500'
              />
            </div>

            {errors.name && (
              <p className='mt-2 text-sm text-red-600' id='email-error'>
                Please type in a name
              </p>
            )}
          </div>
        </div>
        <div className='mt-5 sm:mt-6 sm:flex sm:flex-row-reverse'>
          <Button size={'md'} color='secondary' type='submit' isLoading={isSubmitting}>
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
